import classNames from 'classnames';
import React, { FunctionComponent } from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  maxWidth?: string;
}

export const ResponsiveContainer: FunctionComponent<Props> = ({ children, className, style, maxWidth }) => {
  return (
    <div className={classNames('px-4 sm:px-6 lg:px-8 pb-8 relative', className)} style={style}>
      <div className={classNames('mx-auto', { 'max-w-screen-xl': !maxWidth }, maxWidth)}>{children}</div>
    </div>
  );
};
