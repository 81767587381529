import { useLayoutEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export function useScreenSize() {
  const [isClient, setClient] = useState(false);

  const is2Xl = useMediaQuery({ query: '(min-width: 1536px)' });
  const isXl = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLg = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMd = useMediaQuery({ query: '(min-width: 768px)' });
  const isSm = useMediaQuery({ query: '(min-width: 640px)' });

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') setClient(true);
  }, []);

  return {
    isClient,

    isSm: isClient && isSm,
    isMd: isClient && isMd,
    isLg: isClient && isLg,
    isXl: isClient && isXl,
    is2Xl: isClient && is2Xl,

    isDesktop: isClient && isLg,
    isMobile: isClient ? !isSm && !isMd && !isLg && !isXl && !is2Xl : true,
    isTablet: isClient && isMd,

    isLessThanDesktop: isClient && !isLg && (isMd || isSm || !isSm)
  };
}
