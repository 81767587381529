import { ServiceModalityE } from '@/types/cyclone/models';
import { faFacebook, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

export const MEDIA_LINKS = [
  { link: 'https://facebook.com/fb.agora.red', icon: faFacebook },
  { link: 'https://www.instagram.com/agora.red/', icon: faInstagram },
  { link: 'https://www.linkedin.com/company/agora-red', icon: faLinkedinIn }
];

export const GOOGLE_LINKS = {
  MAPS: 'https://maps.google.com'
};

export const LINKS = {
  SUPPORT_WHATSAPP: 'https://wa.me'
};

export const DAYS_OF_WEEK = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo'];

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
];

export const OPTIONS_MODALITIES: Record<ServiceModalityE, string> = {
  local: 'Presencial',
  virtual: 'Online',
  open: 'Ambas'
};
